import axios from '@/plugins/axios'

/**
 * Activate campaign line
 */
const activateCampaignLine = async (id) => {
  try {
    const response = await axios.post(`lines/${id}/activate`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create campaign line
 */
const createCampaignLine = async (form) => {
  try {
    const response = await axios.post('lines', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Pause campaign line
 */
const pauseCampaignLine = async (id) => {
  try {
    const response = await axios.post(`lines/${id}/pause`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Retry creation on platform
 */
const retryCreationOnPlatform = async id => {
  try {
    const response = await axios.post(`lines/${id}/retry`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update campaign line
 */
const updateCampaignLine = async (id, form) => {
  try {
    form._method = 'PUT'
    const response = await axios.post(`lines/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  activateCampaignLine,
  createCampaignLine,
  pauseCampaignLine,
  retryCreationOnPlatform,
  updateCampaignLine
}
